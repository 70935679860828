<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0">Thống kê Voucher</h6>
                </template>
                <div class="filter">
                    <b-row>
                        <b-col>
                            <b-form-group label="Điều kiện tìm kiếm">
                                <b-form-select :options="source_type" v-model="filter.source_type"></b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col v-if="filter.source_type === 'game_id'">
                            <b-form-group label="Game ID">
                                <b-input v-model="filter.game_id"></b-input>
                            </b-form-group>
                        </b-col>

                        <b-col v-if="filter.source_type === 'service'">
                            <b-form-group label="Dịch vụ">
                                <b-form-select :options="services" v-model="filter.order_action"></b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group label="Camp ID">
                                <b-input v-model="filter.camp_id"></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Voucher ID">
                                <b-input v-model="filter.voucher_id"></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Voucher Name">
                                <b-input v-model="filter.voucher_name"></b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Thời gian bắt đầu(*)">
                                <b-form-input
                                    :id="`type-date`"
                                    :type="`date`"
                                    v-model="filter.date_from"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Thời gian kết thúc(*)">
                                <b-form-input
                                    :id="`type-date`"
                                    :type="`date`"
                                    v-model="filter.date_to"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="text-right">
                                <b-button-group>
                                    <b-button type="button" variant="primary" @click="search()"><i
                                        class="fa fa-search"></i> Tìm kiếm
                                    </b-button>
                                    <b-button type="button" variant="success" @click="showModal()"><i
                                        class="fa fa-download"></i> Xuất Excel
                                    </b-button>
                                </b-button-group>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="content">
                    <b-summary :total="paginate.total" :current-page="paginate.page" :per-page="paginate.limit"
                               :total-page="paginate.totalPage"></b-summary>

                    <b-table :items="items" :fields="fields" ref="table" striped hover responsive caption-top>
                        <template #cell(index)="data">
                            {{ (currentPage - 1) * 25 + (data.index + 1) }}
                        </template>

                        <template #cell(gmv)="data">
                            {{ numberFormat(data.item.gmv) }}
                        </template>

                        <template #cell(budget)="data">
                            {{ numberFormat(data.item.budget) }}
                        </template>

                        <template #cell(trans)="data">
                            {{ numberFormat(data.item.trans) }}
                        </template>

                        <template #cell(order_action)="data">
                            {{ data.item.order_action ?? "TOPUP_GAME" }}
                        </template>
                    </b-table>
                </div>

                <div class="mt-3">
                    <b-pagination-nav
                        v-model="currentPage"
                        :link-gen="createLinkPage"
                        :number-of-pages="paginate.totalPage"
                        use-router
                        first-number
                        last-number
                        align="right"
                    ></b-pagination-nav>
                </div>

            </b-card>
        </b-card-group>

        <export-modal :modalId="exportId" @exportData="handleExport"></export-modal>
        <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import router from "@/router";
import BSummary from "@/view/pages/components/Summary.vue";

Vue.use(Notifications);

const ReconcileRepository = RepositoryFactory.get("cms");
export default {
    components: {BSummary, AlertDialogue, ExportModal},
    mixins: [Common],
    data() {
        return {
            exportId: "export_marketing_voucher",
            items: null,
            process: 0,
            target: 0,
            currentPage: 1,
            source_type: [
                {value: "service", text: "Dịch vụ"},
                {value: "game_id", text: "Game ID"},
            ],
            services: [
                {value: "", text: "Tất cả"},
            ],
            paginate: {
                limit: 25,
                page: 1,
                totalPage: 1,
                total: 0,
                skip: 0,
            },
            filter: {
                source_type: "service",
                camp_id: null,
                game_id: null,
                voucher_id: null,
                order_action: null,
                voucher_name: null,
                date_from: this.getFirstDayOfMonth(),
                date_to: this.getCurrentDay(),
            },
            fields: [],
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Thống kê Voucher", route: "marketing/voucher"},
        ]);
    },
    methods: {
        search() {
            this.filter.page = 1;
            router.push({path: "/marketing/voucher", query: this.filter}).catch(() => {
            });

            if (this.currentPage !== 1) {
                this.currentPage = 1;
                return;
            }

            this.getTransactions();
        },
        async getServices() {
            this.$bus.$emit("show-loading", true);
            let response = await ReconcileRepository.services();

            this.$bus.$emit("show-loading", false);
            let body = response.data;
            if (body.error_code) {
                this.notifyAlert("warn", response.data.message);
                return true;
            }

            let services = body.data;

            if (services.length <= 0) {
                return;
            }

            let self = this;
            services.forEach(function (item) {
                self.services.push({value: item, text: item});
            });
        },
        async getTransactions() {
            this.$bus.$emit("show-loading", true);
            this.items = null;

            let fields = [
                {
                    key: "index",
                    label: "STT",
                },
                {
                    key: "voucher_id",
                    label: "Voucher ID",
                },
                {
                    key: "voucher_name",
                    label: "Voucher Name",
                },
                {
                    key: "camp_id",
                    label: "Camp ID",
                },
                {
                    key: "gmv",
                    label: "GMV",
                },
                {
                    key: "budget",
                    label: "Budget",
                },
                {
                    key: "trans",
                    label: "Transaction",
                },
                {
                    key: "pu",
                    label: "PU",
                },
            ];

            if (this.filter.source_type === "service") {
                fields.push({key: "order_action", label: "Dịch vụ"});
            } else {
                fields.push({key: "game_id", label: "Game ID"});
            }

            this.fields = fields;

            let params = this.convert(this.filter);
            params.page = this.currentPage;
            let response = await ReconcileRepository.listVoucher(params);
            this.$bus.$emit("show-loading", false);
            let body = response.data;
            if (body.error_code) {
                this.notifyAlert("warn", response.data.message);
                return true;
            }

            this.items = body.data.data;
            this.paginate.totalPage = body.data.last_page;
            this.paginate.total = body.data.total;
            this.paginate.page = body.data.current_page;
            this.paginate.limit = body.data.per_page;
        },
        showModal() {
            this.$bvModal.show(this.exportId);
        },
        async handleExport(description = null) {
            this.$bus.$emit("show-loading", true);
            let params = this.convert(this.filter);
            params.export_desc = description;
            params.page = this.currentPage;
            let response = await ReconcileRepository.exportVoucher(params);
            this.$bus.$emit("show-loading", false);
            this.$bvModal.hide(this.exportId);

            if (response.data.error_code !== 0) {
                this.notifyAlert("error", response.data.message);
                return false;
            }

            let link = window.location.origin + "/#/reconcile/export-data";

            const cfm = await this.$refs.confirmDialogue.show({
                title: "Download File",
                message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
                okButton: "Ok",
            });
            if (!cfm) {
                return false;
            }
        },
        convert(params) {
            Object.keys(params).forEach(key => {
                if (params[key] === null || params[key] == '') {
                    delete params[key];
                }
            });
            return params;
        },
    },
    created() {
        this.getServices();
        this.getTransactions();
    },
    watch: {
        currentPage(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.getTransactions();
            }
        },
    },
};
</script>